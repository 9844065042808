<template>
  <b-row class="h-100">
    <b-colxx xxs="12" md="10" class="mx-auto my-auto">
      <b-card class="auth-card" no-body>
        <div class="position-relative image-side"></div>
        <div class="form-side">
          <span class="logo-text">{{appTitle}}</span>
          <h6 class="mb-4">Forgot Password</h6>

          <b-form @submit.prevent="formSubmit" class="av-tooltip tooltip-label-bottom">
            <b-input-group class="mb-4">
              <b-input-group-prepend is-text>
                <i class="demo-icon iconsminds-email"></i>
              </b-input-group-prepend>
              <b-form-input
                type="text"
                v-model="$v.form.email.$model"
                :state="!$v.form.email.$error"
                placeholder="test@test.com"
              />
              <div
                v-if="!$v.form.email.required"
                class="invalid-tooltip"
              >Please enter your email address</div>
              <div
                v-else-if="!$v.form.email.email"
                class="invalid-tooltip"
              >Please enter a valid email address</div>
              <div
                v-else-if="!$v.form.email.minLength"
                class="invalid-tooltip"
              >Your email must be minimum 4 characters</div>
            </b-input-group>

            <div class="d-flex justify-content-between align-items-center">
              <router-link tag="a" to="/">Cancel</router-link>
              <b-button
                type="submit"
                variant="primary"
                size="lg"
                :disabled="processing"
                :class="{'btn-multiple-state btn-shadow': true,
                    'show-spinner': processing,
                    'show-success': !processing && loginError===false,
                    'show-fail': !processing && loginError }"
              >
                <span class="spinner d-inline-block">
                  <span class="bounce1"></span>
                  <span class="bounce2"></span>
                  <span class="bounce3"></span>
                </span>
                <span class="icon success">
                  <i class="simple-icon-check"></i>
                </span>
                <span class="icon fail">
                  <i class="simple-icon-exclamation"></i>
                </span>
                <span class="label">SEND</span>
              </b-button>
            </div>
          </b-form>
        </div>
      </b-card>
    </b-colxx>
  </b-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
const {
  required,
  maxLength,
  minLength,
  email
} = require("vuelidate/lib/validators");

export default {
  data() {
    return {
      form: {
        email: ""
      },
      appTitle: process.env.VUE_APP_TITLE
    };
  },
  mixins: [validationMixin],
  validations: {
    form: {
      email: {
        required,
        email,
        minLength: minLength(4)
      }
    }
  },
  computed: {
    ...mapGetters(["processing", "loginError", "forgotMailSuccess"])
  },
  methods: {
    ...mapActions(["forgotPassword"]),
    formSubmit() {
      this.$v.form.$touch();
      if (!this.$v.form.$anyError) {
        this.forgotPassword({
          email: this.form.email
        });
      }
    }
  },
  watch: {
    loginError(val) {
      if (val != null) {
        this.$notify("error", "Forgot Password Error", val, {
          duration: 3000,
          permanent: false
        });
      }
    },
    forgotMailSuccess(val) {
      if (val) {
        this.$notify(
          "success",
          "Forgot Password Success",
          "Please check your email.",
          {
            duration: 3000,
            permanent: false
          }
        );
      }
    }
  }
};
</script>
