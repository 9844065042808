import i18n from '@/i18n'

export const appHighlights = [
  {
    title: i18n.t('app-highlights.highlight1', { sitename: i18n.t('general.sitename') }),
    img: '/assets/img/svg/application-highlights/team-meeting.svg'
  },
  {
    title: i18n.t('app-highlights.highlight2'),
    img: '/assets/img/svg/application-highlights/progress.svg'
  },
  {
    title: i18n.t('app-highlights.highlight3'),
    img: '/assets/img/svg/application-highlights/information-carousel.svg'
  },
  {
    title: i18n.t('app-highlights.highlight4'),
    img: '/assets/img/svg/application-highlights/brainstorming-session.svg'
  },
  {
    title: i18n.t('app-highlights.highlight5'),
    img: '/assets/img/svg/application-highlights/winners.svg'
  }
]
