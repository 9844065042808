<template>
  <div class="notification-container">
    <auth-notification-bar
      v-for="notification in authNotifications"
      :key="notification.id"
      :notification="notification"
    ></auth-notification-bar>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AuthNotificationBar from './AuthNotificationBar.vue'
export default {
  components: {
    AuthNotificationBar
  },
  computed: mapState('notification', ['authNotifications'])
}
</script>
