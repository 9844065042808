<template>
  <carousel
    :per-page="1"
    :mouse-drag="true"
    :autoplay="true"
    :autoplayHoverPause="true"
    :loop="true"
    :autoplayTimeout="autoplayTimeout"
    class="application-highlights-glide d-flex flex-row row justify-content-center"
  >
    <slide v-for="(app, index) in appHighlights" :key="`app-${index}`" class="px-3">
      <div class="col-12 glide-main pt-3">
        <b-row cols="p-0 m-0">
          <div class="col-12 my-4" align="center">
            <img :src="app.img" />
          </div>
          <div class="col-12" align="center">
            <p v-html="app.title"></p>
          </div>
        </b-row>
      </div>
    </slide>
  </carousel>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel'
import { appHighlights } from '@/data/applicationHighlights'
export default {
  components: {
    Carousel,
    Slide
  },
  data() {
    return {
      appHighlights,
      autoplayTimeout: 3000,
      glideBasicOption: {
        gap: 4,
        perView: 1,
        type: 'slider',
        rewind: true,
        bound: true,
        hideBullets: false,
        autoplay: false
      }
    }
  }
}
</script>
