<template>
  <div id="root">
    <div class="fixed-background"></div>
    <main>
      <div class="container">
        <router-view />
      </div>
      <footer-component />
    </main>
    <auth-notification-container></auth-notification-container>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import AuthNotificationContainer from '@/components/Notification/AuthNotificationContainer.vue'
import Footer from '@/containers/Footer'
export default {
  components: {
    'footer-component': Footer,
    AuthNotificationContainer
  },
  data() {
    return {
      error: null,
      visible: true
    }
  },
  methods: {
    ...mapMutations(['setLoginError']),
    display() {
      let self = this
      self.visible = true
      self.timeout = setTimeout(() => {
        self.visible = false
        self.setLoginError(null)
      }, 3000)
    }
  },
  computed: {
    ...mapGetters(['loginError'])
  },
  mounted() {
    document.body.classList.add('background')
  },
  watch: {
    loginError(newValue) {
      if (newValue) {
        this.error = newValue
        this.display()
      }
    }
  },
  beforeDestroy() {
    document.body.classList.remove('background')
  }
}
</script>
