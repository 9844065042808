<template>
  <b-row class="h-100">
    <b-colxx xxs="12" md="10" class="mx-auto my-auto">
      <b-card class="auth-card" no-body>
        <div class="position-relative image-side"></div>
        <div class="form-side">
          <span class="logo-text">{{appTitle}}</span>
          <h6 class="mb-4">Register</h6>
          <b-form @submit.prevent="formSubmit">
            <!-- <label class="form-group has-float-label mb-4">
              <input type="text" class="form-control" v-model="fullname" />
              <span>{{ $t('user.fullname') }}</span>
            </label>
            <label class="form-group has-float-label mb-4">
              <input type="email" class="form-control" v-model="email" />
              <span>{{ $t('user.email') }}</span>
            </label>
            <label class="form-group has-float-label mb-4">
              <input type="password" class="form-control" v-model="password" />
              <span>{{ $t('user.password') }}</span>
            </label>-->

            <b-input-group class="mb-4">
              <b-input-group-prepend is-text>
                <i class="demo-icon simple-icon-user"></i>
              </b-input-group-prepend>
              <b-form-input
                type="text"
                v-model="$v.form.email.$model"
                :state="!$v.form.email.$error"
                placeholder="Email Address"
              />
              <div
                v-if="!$v.form.email.required"
                class="invalid-tooltip"
              >Please enter your email address</div>
              <div
                v-else-if="!$v.form.email.email"
                class="invalid-tooltip"
              >Please enter a valid email address</div>
              <div
                v-else-if="!$v.form.email.minLength"
                class="invalid-tooltip"
              >Your email must be minimum 4 characters</div>
            </b-input-group>

            <b-input-group class="mb-5">
              <b-input-group-prepend is-text>
                <i class="demo-icon simple-icon-lock"></i>
              </b-input-group-prepend>
              <b-form-input
                type="password"
                v-model="$v.form.password.$model"
                :state="!$v.form.password.$error"
                placeholder="Password"
              />
              <div
                v-if="!$v.form.password.required"
                class="invalid-tooltip"
              >Please enter your password</div>
              <div
                v-else-if="!$v.form.password.minLength || !$v.form.password.maxLength"
                class="invalid-tooltip"
              >Your password must be between 4 and 16 characters</div>
            </b-input-group>

            <b-input-group class="mb-5">
              <b-input-group-prepend is-text>
                <i class="demo-icon simple-icon-lock"></i>
              </b-input-group-prepend>
              <b-form-input
                type="password"
                v-model="$v.form.confirmPassword.$model"
                :state="!$v.form.confirmPassword.$error"
                placeholder="Confirm Password"
              />
              <div
                v-if="!$v.form.confirmPassword.required"
                class="invalid-tooltip"
              >Please enter your password</div>
              <div
                v-else-if="!$v.form.confirmPassword.minLength || !$v.form.confirmPassword.maxLength"
                class="invalid-tooltip"
              >Your password must be between 4 and 16 characters</div>
            </b-input-group>

            <div class="d-flex justify-content-between align-items-center">
              <router-link tag="a" to="/">Cancel</router-link>
              <b-button
                type="submit"
                variant="primary"
                size="lg"
                class="btn-shadow"
              >REGISTER</b-button>
            </div>
          </b-form>
        </div>
      </b-card>
    </b-colxx>
  </b-row>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
const {
  required,
  maxLength,
  minLength,
  email
} = require("vuelidate/lib/validators");

export default {
  data() {
    return {
      form: {
        email: "",
        password: "",
        confirmPassword: ""
      },
      appTitle: process.env.VUE_APP_TITLE
    };
  },
  mixins: [validationMixin],
  validations: {
    form: {
      email: {
        required,
        email,
        minLength: minLength(4)
      },
      password: {
        required,
        maxLength: maxLength(16),
        minLength: minLength(4)
      },
      confirmPassword: {
        required,
        maxLength: maxLength(16),
        minLength: minLength(4)
      }
    }
  },
  computed: {
    ...mapGetters(["currentUser", "processing", "loginError"])
  },
  methods: {
    formSubmit() {
      console.log("register");
      this.$router.push("/");
    }
  }
};
</script>
